import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"

import ictConsultingIllustration from "./../../images/illustration/ict_consulting.svg";
import managedServiceSolutionsIllustration from "./../../images/illustration/managed_service_solutions.svg";
import enterpriseSolutionsIllustration from "./../../images/illustration/enterprise_solutions.svg";


const MainContainer = styled.section`
    width: 100%;
    background-color: #F9FAFE;

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        padding: 30px 0;
    }
    padding: 50px 0;

`

const Wrapper = styled.div`
    width: 80%;
    margin: 0px auto;
    text-align: center;
    
    h1 {
        font-size: 2rem;
        margin: 0 0 20px 0px;
        font-weight: 500;
        font-family: 'Rufina', serif;
    }

    p {
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: #4f4f4f;
        margin: 0 auto;
        width: 100%;
    }

    a {
        font-size: 1.3rem;
        color: #e7515f;
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        width: 90%;
        text-align: center;
    }

}`;



const Illustration = styled.div`
    width: 80%;
    height: 200px;
    margin: 35px auto;
    background: url('${props => props.illustration}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        height: 50%;
        width: 100%;
    }
`;


const Card = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(60,66,68,.25);
    transition: all .3s ease-out;
    border-radius: 5px;

    h1 {
        font-size: 1.2rem;
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0 50px 50px -30px rgba(60,66,68,.3)
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        height: 300px;
        margin-bottom: 20px;
    }

`;

const CardHolder = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 40px 0 20px 0;
    flex-wrap: wrap;

    a {
        width: 32%;
        text-decoration: none;
        color: initial;
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {

        a {
            width: 100%;
        }
    }
`

const LandingPageCardStream = (props) => {
    return (
        <MainContainer>
            <Wrapper>
                <h1>Our Services</h1>
                <CardHolder>
                    <Link to="/services#ict-consulting">
                        <Card>
                            <Illustration illustration={ictConsultingIllustration}/>
                            <h1>ICT Consulting</h1>
                        </Card>
                    </Link>
                    <Link to="/services#enterprise-solutions">
                        <Card>
                            <Illustration illustration={enterpriseSolutionsIllustration}/>
                            <h1>Enterprise Solutions</h1>
                        </Card>
                    </Link>
                    <Link to="/services#managed-service-solutions">
                        <Card>
                            <Illustration illustration={managedServiceSolutionsIllustration}/>
                            <h1>Managed Service Solutions</h1>
                        </Card>
                    </Link>
                </CardHolder>
            </Wrapper>
        </MainContainer>
    )
}

export default LandingPageCardStream;