import React from "react"
import { Link } from "gatsby"
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "./../components/footer";

import IllustrationBlock from "./../components/illustration-block";
import SimpleCapsule from "./../components/simple-capsule";

import LandingPageHero from "./../components/LandingPage/LandingPageHero";

import Button from "./../components/button";
import LandingPageCardStream from "../components/LandingPage/LandingPageCardStream";

const StyleLessLink = styled(Link)`
  text-decoration: none;
  color: initial;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home"/>
    <LandingPageHero background={`#F9FAFE`} />
    <IllustrationBlock direction={`row-reverse`} type={`image`} illustration={`dubai`} image background={`#F9FAFE`}>
      <h1>Who we are</h1>
      <p>Since its establishment in 2015, Synergistic Consultancy Services has strived to provide a client-focused service with speed, integrity and precision.</p>
      <StyleLessLink to="/about"><Button>Learn More</Button></StyleLessLink>
    </IllustrationBlock>
    <LandingPageCardStream background={`#F9FAFE`}/>
    <IllustrationBlock illustration={`careers`} background={`#F9FAFE`}>
      <h1>Careers</h1>
      <p>We aim to take a synergistic approach to solution building and implementation in the area of Information and Communications Technology</p>
      <StyleLessLink to="/careers"><Button>Explore Careers</Button></StyleLessLink>
    </IllustrationBlock>
    <SimpleCapsule theme={{"background": "#2F2E41", "color": "#fff"}}>
      <h1>Contact</h1>
      <p>Based in the United Arab Emirates, our international team provides world-class IT consultancy services and comprehensive staffing solutions at a global scale.</p>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9177.6312486605!2d55.96874784364725!3d25.805453639457298!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef67131f14a49b1%3A0xd3b5ffc06b24ab09!2sRas%20Al%20Khaimah%20Economic%20Zone%20-%20RAKEZ!5e0!3m2!1sen!2sus!4v1586742759722!5m2!1sen!2sus" width="600" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      <StyleLessLink to="/contact"><Button center>Get in touch!</Button></StyleLessLink>
    </SimpleCapsule>
    <Footer/>
  </Layout>
)

export default IndexPage
