import React from "react";

import styled from "styled-components";
import rightArrow from "./../images/icons/arrow-right.svg";

const Button = styled.button`
    margin: ${props => props.center ? `0 auto` : ``};
    background: #EC7580;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 10px 100px 10px 15px;
    text-align: left;
    font-weight: 600;
    background-image: url('${rightArrow}');
    background-repeat: no-repeat;
    background-size: initial;
    background-position: calc(100% - 15px) center;
    display: block;

    &:hover {
        background: #e7515f;
        background-image: url('${rightArrow}');
        background-repeat: no-repeat;
        background-size: initial;
        background-position: calc(100% - 15px) center;
        transition: background 0.3s ease-in-out;
        cursor: pointer;
    }

`

export default Button;