import React from "react";
import styled from "styled-components";
import {Link} from "gatsby";

import droneDeliveryIllustration from "./../../images/illustration/drone_delivery.svg";

import Button from "./../button";

const MainContainer = styled.section`
    width: 100%;
    background-color: #F9FAFE;

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        padding: 30px 0;
    }
    padding: 50px 0;

`

const Wrapper = styled.div`
    width: 50%;
    margin: 0px auto;
    text-align: center;
    
    h1 {
        font-size: 2.4rem;
        margin: 0 0 10px 0px;
        font-weight: 500;
        font-family: 'Rufina', serif;

    }

    p {
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: #4f4f4f;
        margin: 0 auto;
        width: 100%;
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        width: 90%;
        text-align: center;
    }

}`;



const Illustration = styled.div`
    width: 100%;
    height: 300px;
    margin: 35px auto;
    background: url('${props => props.illustration}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        height: 250px;
        width: 100%;
    }
`;


const StyleLessLink = styled(Link)`
  text-decoration: none;
  color: initial;
`



const LandingPageHero = (props) => {
    return (
        <MainContainer>
            <Wrapper>
                <h1>Synergistic Consulting Services</h1>
                <p>Information technology services and comprehensive solutions to help your organization improve efficiency and productivity</p>
                <Illustration illustration={droneDeliveryIllustration}/>
                <StyleLessLink to="/services"><Button center>Explore services</Button></StyleLessLink>
            </Wrapper>
        </MainContainer>
    )
}

export default LandingPageHero;