import React from "react";
import styled from "styled-components";

const SimpleCapsuleContainer = styled.section`
    width: 100%;
    background: ${props => props.theme.background};
    color: ${props => props.theme.color};
    padding: 35px 0;
`;

const SimpleCapsuleWrapper = styled.div`
    width: 70%;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin: 0;
        padding: 20px 0;
        font-size: 2rem;
        font-weight: 500;
        font-family: 'Rufina', serif;
    }

    p {
        font-size: 1rem;
        line-height: 1.8rem;
        width: 80%;
        margin: 0 auto;
    }
    
    button {
        margin: 0 auto;
    }

    iframe {
        margin: 30px 0;
        border-radius: 10px;
        border: 0;
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {

        width: 90%;

        button {
            margin: 0 auto;
        }

        p {
            width: 100%;
        }

        iframe {
            width: 100%;
            margin: 20px auto;
        }

    }
`;


const SimpleCapsule = ({children, theme}) => {
    return (
        <SimpleCapsuleContainer theme={theme}>
            <SimpleCapsuleWrapper>
                {children}
            </SimpleCapsuleWrapper>
        </SimpleCapsuleContainer>
    )
}

export default SimpleCapsule;